// Bootstrap
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import "../app/variables";

// TABLE

$th-table-color: $dark;

// LAYOUT

$content-bg: #f5f7fa;
$aside-bg: #fff;

// NAVBAR TOP

$nav-top-bg: $secondary;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $nav-top-bg;
$nav-header-bg: transparent;

$nav-top-item: $primary;
$nav-top-item-active: darken($nav-top-item, 20%);

// SIDEBAR

$sidebar-bg: $aside-bg;
$sidebar-subnav-bg: #efefef;

$sidebar-item-color: #515253;
$sidebar-item-color-active: $primary;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $primary;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

// OFFSIDEBAR

$offsidebar-bg: #fff;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $body-color;

/* ========================================================================
   Component: table
 ========================================================================== */
table.table.table-striped thead tr th {
    color: $th-table-color;
}

/* ========================================================================
   Component: layout
 ========================================================================== */
body,
.wrapper .section-container {
    background-color: $content-bg;
}

.wrapper .aside-container {
    background-color: $aside-bg;
}

/* ========================================================================
   Component: top-navbar
 ========================================================================== */
.topnavbar {
    background-color: $nav-top-bg;
    border-bottom: 1px solid $gray;
    @include gradient-x($nav-top-bg-start, $nav-top-bg-end);

    @include media-breakpoint-up(lg) {
        .navbar-nav > .nav-item.show > .nav-link {
            &,
            &:hover,
            &:focus {
                box-shadow: 0 -3px 0 darken($nav-top-bg, 6%) inset;
            }
        }
    }

    .navbar-nav > .nav-item > .navbar-text {
        color: $nav-top-item;
    }

    .navbar-nav > .nav-item > .nav-link,
    .navbar-nav > .nav-item.show > .nav-link {
        color: $nav-top-item;
        &:hover,
        &:focus {
            color: $nav-top-item-active;
        }
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: $nav-top-bg;
    }
}

/* ========================================================================
   Component: sidebar
 ========================================================================== */
.sidebar {
    background-color: $sidebar-bg;

    .nav-heading {
        color: $sidebar-heading-color;
    }
}

nav.sidebar {
    margin-right: 0px !important;
}

.sidebar-nav div.item.user-block {
    background-color: $gray-100;
    padding: 30px 10px 15px 10px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
        font-size: 1.6em;
        margin-bottom: 10px;
    }
    .user-block-role {
        color: $gray-600;
    }
}

// Items
.sidebar-nav {
    > li {
        > a,
        > .nav-item {
            color: $sidebar-item-color;

            &:focus,
            &:hover {
                color: $sidebar-item-color-active;
            }

            // Item icon
            > em {
                color: $sidebar-icon-color;
            }
        }

        // Active item state
        &.active,
        &.open {
            &,
            > a,
            > .nav-item,
            .sidebar-nav {
                background-color: $sidebar-item-bg-active;
                color: $sidebar-item-color-active;
            }

            > .nav-item > em,
            > a > em {
                color: $sidebar-icon-color-active;
            }
        }

        &.active {
            border-left-color: $sidebar-item-color-active;
        }
    }
}

.sidebar-subnav {
    > .sidebar-subnav-header {
        color: $sidebar-item-color;
    }

    > li {
        padding-left: 20px;
        > a,
        > .nav-item {
            color: $sidebar-item-color;

            &:focus,
            &:hover {
                color: $sidebar-item-color-active;
            }
        }

        &.active {
            background-color: $sidebar-subnav-bg;
            > a,
            > .nav-item {
                color: $sidebar-icon-color-active;
                background-color: $sidebar-item-bg-active;

                &:after {
                    border-color: $sidebar-bullet-color-active;
                    background-color: $sidebar-bullet-color-active;
                }
            }
        }
    }
}

/* ========================================================================
   Component: offsidebar
 ========================================================================== */
.offsidebar {
    border-left: 1px solid $offsidebar-border-color;
    background-color: $offsidebar-bg;
    color: $offsidebar-color;
}

@media (max-width: 767px) {
    .sidebar-toggle > em {
        color: $primary;
    }
}
